import React, { useState, useEffect } from 'react';
import axios from 'axios';
//import { createClient }  from 'contentful-management';
import { Link } from "gatsby"
import { Helmet } from "react-helmet";
import Layout from "../components/layout"
import SEO from "../components/seo"
import SecondaryButton from "../components/Button/SecondaryButton"
import { AboutWrapper } from "../components/Section"

import {Nodata, NodataTitle, NodataDesc} from "../components/Nodata"

const ProductPage = () => {
  const [data, setData] = useState(null);
  
  // const client = createClient({
  //   accessToken: 'CFPAT-YODYNxgC0kQdRWkVGGeJqHU8SmCxkJ66kqqr_vbIeLE'
  // })
  // const space = client.getSpace("z75g1qwq4el7");
  //console.log(space);
  //  space.then((space) => space.getEnvironment('master'))
  //   .then((environment) => environment.createEntry('blog', {
  //     fields: {
  //       title: {'en-US':'Aasif Ali Cibirix'},
  //       metaTitle:{'en-US':'shiv title'},
  //       metaDescription:{'en-US':'shiv meta description'},
  //       url:{'en-US':'shiv url'},
  //       description:{'en-US':'shiv description'},
  //       content:{'en-US':'shiv content'}
  //     }
  //   }))
  //   .catch(console.error)

      useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get('https://reqres.in/api/users?page=2');
          setData(response.data.data);
          const mydata = response.data.data;

          // const client = createClient({
          //   accessToken: 'CFPAT-YODYNxgC0kQdRWkVGGeJqHU8SmCxkJ66kqqr_vbIeLE'
          // })
          // const space = client.getSpace("z75g1qwq4el7");
          // const environment = space.getEnvironment('master');

          // mydata.map(item => (
          //   environment.createEntry('blog', {
          //     fields: {
          //       title: {'en-US':item.first_name},
          //       metaTitle:{'en-US':'shiv title'},
          //       metaDescription:{'en-US':'shiv meta description'},
          //       url:{'en-US':'shiv url'},
          //       description:{'en-US':'shiv description'},
          //       content:{'en-US':'shiv content'}
          //     }
          //   })          
          // ))

        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }, []);
  return (
    <Layout>
      <Helmet><meta name="robots" content="noindex, nofollow" /></Helmet>
      <SEO title="404 - Not found" />
      <AboutWrapper>
        <div className="container">
          <Nodata>
            <NodataTitle className="h2">Page Not Found</NodataTitle>           
            <NodataDesc mb="30px">
               {data && (
                  <ul>
                    {data.map(item => (
                      <li key={item.id}>{item.first_name}</li>
                    ))}
                  </ul>
                )}
              <Link to="/"><SecondaryButton text="Go To Home" size="md" /></Link>
            </NodataDesc>
          </Nodata>
        </div>
      </AboutWrapper>
    </Layout>
  )
}

export default ProductPage
